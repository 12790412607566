import { z } from "zod";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";

export enum CompanyStatus {
    active = "active",
    archived = "archived",
    inReview = "inReview",
}

export const companySchema = z.object({
    id: z.number(),
    name: z.string().nullish(),
    ownedById: z.string().nullish(),
    ownedBy: userSchema.nullish(),
    address: z.string().nullish(),
    city: z.string().nullish(),
    state: z.string().nullish(),
    country: z.string().nullish(),
    email: z.string().nullish(),
    website: z.string().nullish(),
    defaultFolder: z.number().nullish(),
    createdAt: z.coerce.date().nullish(),
    updatedAt: z.coerce.date().nullish(),
    phone: z.string().nullish(),
    status: z.nativeEnum(CompanyStatus).nullish(),
    statusUpdatedAt: z.coerce.date().nullish(),
    finalRefundAmount: z.number().nullish(),
    totalFeePercent: z.number().nullish(),
    totalFeeAmount: z.number().nullish(),
    totalFeeType: z.enum(["amount", "percentage"]).nullish(),
    depositFeePercent: z.number().nullish(),
    depositFeeAmount: z.number().nullish(),
    depositFeeType: z.enum(["amount", "percentage"]).nullish(),
    laterFeePercent: z.number().nullish(),
    laterFeeAmount: z.number().nullish(),
    laterFeeType: z.enum(["amount", "percentage"]).nullish(),
    affiliateFeePercentage: z.number().nullish(),
    affiliateFeeAmount: z.number().nullish(),
    reviewerId: z.string().nullish(),
    hedgeFundBuyoutPrice: z.number().nullish(),
    feeHedgeFundBuyoutPrice: z.number().nullish(),
});

export type Company = z.infer<typeof companySchema>;

import { useBreakpoint } from "../hooks/appMedia";
import ButtonNeoGen, { ButtonNeoGenProps } from "./button-neogen";
import Filter from "./filter";
import OptionsDropDown from "./options-dropdown";

export default function PageDescription(props: PageDescriptionProps) {
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    return (
        <main className={(props.doOffset === false ? "" : "-mt-0 ") + "p-0 my-2 pb-3" + " " + props.className}>
            <div className="w-full  px-0 xs:px-6 lg:max-w-full lg:px-0">
                {/* Main 3 column grid */}
                <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 ">
                    {/* Left column */}
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                        {/* Welcome panel */}
                        <section aria-labelledby="profile-overview-title">
                            <div className="rounded-xl bg-white   shadow dark:bg-gray-800">
                                <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow-sm">
                                    <>
                                        <div className="sm:flex sm:items-center sm:justify-between ">
                                            <div className="sm:flex-grow space-x-5 md:space-x-0">
                                                <div className="mt-0 md:pt-0 text-left sm:text-center font-medium text-gray-500 dark:text-green-400 md:flex">
                                                    {props.title && (
                                                        <>
                                                            <div className="sm:flex-auto dark:text-indigo-400">
                                                                <div className="flex justify-between w-full">
                                                                    <p className="text-left text-xl font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                                                                        {props.title}
                                                                    </p>
                                                                    <div className="md:hidden">
                                                                        <OptionsDropDown
                                                                            asEllipses
                                                                            options={(props.buttons || []).map((b) => ({
                                                                                label: b.label || "",
                                                                                action: b.onClick,
                                                                                icon: b.icon,
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {props.description && (
                                                                    <p className="mt-3 mb-1 tracking-wide text-left">
                                                                        {props.description}
                                                                    </p>
                                                                )}
                                                                {props.description2 && (
                                                                    <p className="mb-3 tracking-wide text-left">
                                                                        {props.description2}
                                                                    </p>
                                                                )}
                                                                {props.description3 && (
                                                                    <p className="mb-3 tracking-wide text-left">
                                                                        {props.description3}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}

                                                    {props.children}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="flex md:flex-row flex-col gap-2 justify-start mt-2">
                                                {props.buttons?.map((button, index) => (
                                                    <div key={index} className="sm:flex-none inline-block start ">
                                                        {button.label && !button.disabled && (
                                                            <div className="sm:flex-auto ">
                                                                <ButtonNeoGen
                                                                    type={button.type}
                                                                    onClick={button.onClick}
                                                                >
                                                                    {button.icon && (
                                                                        <span className={button.icon + " mr-2"} />
                                                                    )}

                                                                    {button.label}
                                                                </ButtonNeoGen>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {props.filters?.map((filter) => {
                                            return (
                                                <div className="w-full block flex-auto self-end" key={filter.label}>
                                                    <Filter
                                                        onChange={filter.onChange}
                                                        label={filter.label}
                                                        options={filter.options}
                                                        value={filter.value}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}

type Filter = {
    label: string;
    value: string;
    options: { id: string; name: string }[];
    onChange: (idx: number | string, text?: string) => void;
};

type PageDescriptionProps = {
    children?: any;
    doOffset?: boolean;
    className?: string;
    title?: string;
    description?: string;
    description2?: string;
    description3?: string;
    filters?: Filter[];
    buttons?: ButtonNeoGenProps[];
    type?: "users";
};

import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { fileSchema } from "../domain/file";

export const getFiles = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/file-uploads",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const files = parseSchema(z.object({ total: z.number(), pageItems: z.array(fileSchema) }), response.data);

    return files;
};

import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { SelectField } from "../../../layout/form/select-field";
import { useMutation } from "@tanstack/react-query";
import { useUpdateCompany } from "../../hooks/use-update-company";
import { NumberField } from "../../../layout/form/number-field";
import { Currency } from "../../../billing/components";
import { Td, Th } from "../../../layout/table";
import SwitchNeoGen from "../../../layout/switch-neogen";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../../billing/utils";
import { useController } from "react-hook-form";
import {
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";

const schema = z.object({
    totalFeeAmount: z.number().optional(),
    totalFeePercent: z.number().optional(),
    totalFeeType: z.enum(["amount", "percentage"]),
    depositFeeAmount: z.number().optional(),
    depositFeePercent: z.number().optional(),
    depositFeeType: z.enum(["amount", "percentage"]),
    laterFeeAmount: z.number().optional(),
    laterFeePercent: z.number().optional(),
    laterFeeType: z.enum(["amount", "percentage"]),
    hedgeFundBuyoutEnabled: z.boolean().optional(),
    hedgeFundBuyoutPrice: z.number().nullish(),
    feeHedgeFundBuyoutEnabled: z.boolean().optional(),
    feeHedgeFundBuyoutPrice: z.number().nullish(),
    finalRefundAmount: z.number().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditGrossFeeModal = ({
    onClose,
    company,
    onCompanyUpdated,
    applicationData,
}: {
    onClose: () => any;
    company: Company;
    onCompanyUpdated: (company: Company) => any;
    applicationData?: any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const defaultValues: Data = {
        totalFeePercent: company.totalFeePercent || 20,
        totalFeeAmount: company.totalFeeAmount || company?.finalRefundAmount || 0 * 0.2,
        totalFeeType: company.totalFeeType || "percentage",
        depositFeePercent: company.depositFeePercent || 20,
        depositFeeAmount: company.depositFeeAmount || company?.finalRefundAmount || 0 * 0.2,
        depositFeeType: company.depositFeeType || "percentage",
        laterFeePercent: company.laterFeePercent || 0,
        laterFeeAmount: company.laterFeeAmount || 0,
        laterFeeType: company.laterFeeType || "percentage",
        hedgeFundBuyoutEnabled: !!company.hedgeFundBuyoutPrice,
        hedgeFundBuyoutPrice: company.hedgeFundBuyoutPrice || undefined,
        feeHedgeFundBuyoutEnabled: !!company.feeHedgeFundBuyoutPrice,
        feeHedgeFundBuyoutPrice: company.feeHedgeFundBuyoutPrice || undefined,
        finalRefundAmount: company.finalRefundAmount || undefined,
    };
    const form = useForm({ schema, defaultValues });
    const updateCompanyMutation = useUpdateCompany();
    const [errorMessage, setErrorMessage] = useState("");

    const values = form.watch();
    const finalRefundAmount = values.finalRefundAmount || 0;

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: {
                    totalFeePercent: data.totalFeePercent,
                    totalFeeAmount: data.totalFeeAmount,
                    totalFeeType: data.totalFeeType,
                    depositFeePercent: data.depositFeePercent,
                    depositFeeAmount: data.depositFeeAmount,
                    depositFeeType: data.depositFeeType,
                    laterFeePercent: data.laterFeePercent,
                    laterFeeAmount: data.laterFeeAmount,
                    laterFeeType: data.laterFeeType,
                    hedgeFundBuyoutPrice: data.hedgeFundBuyoutEnabled ? data.hedgeFundBuyoutPrice : null,
                    feeHedgeFundBuyoutPrice: data.feeHedgeFundBuyoutEnabled ? data.feeHedgeFundBuyoutPrice : null,
                    finalRefundAmount: data.finalRefundAmount,
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        setErrorMessage("");
        if (data.totalFeeAmount !== (data.depositFeeAmount || 0) + (data.laterFeeAmount || 0)) {
            form.setError("depositFeeAmount", { message: `Must sum to ${formatCurrency(data.totalFeeAmount || 0)}` });
            form.setError("laterFeeAmount", { message: `Must sum to ${formatCurrency(data.totalFeeAmount || 0)}` });
            setErrorMessage(`Fee amount must sum to the total of ${formatCurrency(data.totalFeeAmount || 0)}`);
            return;
        }
        if (data.totalFeePercent !== (data.depositFeePercent || 0) + (data.laterFeePercent || 0)) {
            form.setError("depositFeePercent", { message: `Must sum to ${data.totalFeePercent}%` });
            form.setError("laterFeePercent", { message: `Must sum to ${data.totalFeePercent}%` });
            setErrorMessage(`Fee percent must sum to the total of ${data.totalFeePercent}%`);
            return;
        }

        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    const hedgeFundBuyoutEnabledController = useController({ name: "hedgeFundBuyoutEnabled", control: form.control });

    const feeHedgeFundBuyoutEnabledController = useController({
        name: "feeHedgeFundBuyoutEnabled",
        control: form.control,
    });

    const w2 = getW2Employees({ data: applicationData });
    const estimatedAmount = getEstimatedPayoutFromData({ data: applicationData });

    return (
        <>
            <ModalDialog show title={"ClearERC Gross Fee"} close={onClose} showOk={false} showCancel={false} size="md">
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
                    {errorMessage && <p className="bg-red-100 text-red-600 p-4 rounded-lg">{errorMessage}</p>}
                    <div className="flex flex-col gap-1 mt-3" style={{ fontSize: 14 }}>
                        <div className="flex flex-col gap-1" style={{ fontSize: 14 }}>
                            <div className="flex flex-row gap-5">
                                <div>
                                    W2 2020: <b>{w2.w2Employees2020}</b>
                                </div>
                                <div>
                                    W2 2021: <b>{w2.w2Employees2021}</b>
                                </div>
                                <div>
                                    Estimated deal value:{" "}
                                    <b>
                                        <Currency amount={estimatedAmount} />
                                    </b>
                                </div>
                            </div>
                            <div className="mt-3">
                                <NumberField
                                    label="Final Refund Amount"
                                    {...form.getFieldProps("finalRefundAmount")}
                                    onChange={(event) => {
                                        const finalRefundAmount = Number.parseFloat(event.target.value);
                                        if (values.totalFeeType === "percentage") {
                                            form.setValue(
                                                "totalFeeAmount",
                                                values.totalFeeType === "percentage"
                                                    ? (finalRefundAmount || 0) * ((values.totalFeePercent || 0) / 100)
                                                    : undefined,
                                            );
                                        }
                                        if (values.depositFeeType === "percentage") {
                                            form.setValue(
                                                "depositFeeAmount",
                                                values.depositFeeType === "percentage"
                                                    ? (finalRefundAmount || 0) * ((values.depositFeePercent || 0) / 100)
                                                    : undefined,
                                            );
                                        }
                                        if (values.laterFeeType === "percentage") {
                                            form.setValue(
                                                "laterFeeAmount",
                                                values.laterFeeType === "percentage"
                                                    ? (finalRefundAmount || 0) * ((values.laterFeePercent || 0) / 100)
                                                    : undefined,
                                            );
                                        }
                                        if (values.totalFeeType === "amount") {
                                            form.setValue(
                                                "totalFeePercent",
                                                values.totalFeeType === "amount"
                                                    ? ((values.totalFeeAmount || 0) / (finalRefundAmount || 1)) * 100
                                                    : undefined,
                                            );
                                        }
                                        if (values.depositFeeType === "amount") {
                                            form.setValue(
                                                "depositFeePercent",
                                                values.depositFeeType === "amount"
                                                    ? ((values.depositFeeAmount || 0) / (finalRefundAmount || 1)) * 100
                                                    : undefined,
                                            );
                                        }
                                        if (values.laterFeeType === "amount") {
                                            form.setValue(
                                                "laterFeePercent",
                                                values.laterFeeType === "amount"
                                                    ? ((values.laterFeeAmount || 0) / (finalRefundAmount || 1)) * 100
                                                    : undefined,
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className="mt-3 mb-3" />
                    <div>
                        <table style={{ width: "100%", tableLayout: "fixed" }}>
                            <thead>
                                <tr>
                                    <Th />
                                    <Th style={{ width: 220 }}>Amount</Th>
                                    <Th style={{ width: 220 }}>Percent</Th>
                                    <Th style={{ width: 190 }}>Type</Th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <Td style={{ fontWeight: "bold" }}>Total:</Td>
                                    <Td>
                                        <NumberField
                                            noMargin
                                            {...form.getFieldProps("totalFeeAmount")}
                                            onChange={(e) => {
                                                form.setValue("totalFeeType", "amount");
                                                form.setValue(
                                                    "totalFeePercent",
                                                    Number.parseFloat(
                                                        (
                                                            (Number.parseFloat(e.target.value || "0") * 100) /
                                                            finalRefundAmount
                                                        ).toFixed(2),
                                                    ),
                                                );
                                            }}
                                        />
                                    </Td>
                                    <Td>
                                        <NumberField
                                            noMargin
                                            {...form.getFieldProps("totalFeePercent")}
                                            onChange={(e) => {
                                                form.setValue("totalFeeType", "percentage");
                                                form.setValue(
                                                    "totalFeeAmount",
                                                    Number.parseFloat(
                                                        (
                                                            finalRefundAmount *
                                                            (Number.parseFloat(e.target.value || "0") / 100)
                                                        ).toFixed(2),
                                                    ),
                                                );
                                            }}
                                        />
                                    </Td>
                                    <Td>
                                        <SelectField
                                            isSearchable={false}
                                            noMargin
                                            {...form.getFieldProps("totalFeeType")}
                                            options={[
                                                { value: "percentage", label: "Percent" },
                                                { value: "amount", label: "Amount" },
                                            ]}
                                        />
                                    </Td>
                                </tr>
                                <tr>
                                    <Td style={{ fontWeight: "bold" }}>Deposit:</Td>
                                    <Td>
                                        <NumberField
                                            noMargin
                                            {...form.getFieldProps("depositFeeAmount")}
                                            onChange={(e) => {
                                                form.setValue("depositFeeType", "amount");
                                                form.setValue(
                                                    "depositFeePercent",
                                                    Number.parseFloat(
                                                        (
                                                            (Number.parseFloat(e.target.value || "0") * 100) /
                                                            finalRefundAmount
                                                        ).toFixed(2),
                                                    ),
                                                );
                                            }}
                                        />
                                    </Td>
                                    <Td>
                                        <NumberField
                                            noMargin
                                            {...form.getFieldProps("depositFeePercent")}
                                            onChange={(e) => {
                                                form.setValue("depositFeeType", "percentage");
                                                form.setValue(
                                                    "depositFeeAmount",
                                                    Number.parseFloat(
                                                        (
                                                            finalRefundAmount *
                                                            (Number.parseFloat(e.target.value || "0") / 100)
                                                        ).toFixed(2),
                                                    ),
                                                );
                                            }}
                                        />
                                    </Td>
                                    <Td>
                                        <SelectField
                                            isSearchable={false}
                                            noMargin
                                            {...form.getFieldProps("depositFeeType")}
                                            options={[
                                                { value: "percentage", label: "Percent" },
                                                { value: "amount", label: "Amount" },
                                            ]}
                                        />
                                    </Td>
                                </tr>
                                <tr>
                                    <Td style={{ fontWeight: "bold" }}>Later:</Td>
                                    <Td>
                                        <NumberField
                                            noMargin
                                            {...form.getFieldProps("laterFeeAmount")}
                                            onChange={(e) => {
                                                form.setValue("laterFeeType", "amount");
                                                form.setValue(
                                                    "laterFeePercent",
                                                    Number.parseFloat(
                                                        (
                                                            (Number.parseFloat(e.target.value || "0") * 100) /
                                                            finalRefundAmount
                                                        ).toFixed(2),
                                                    ),
                                                );
                                            }}
                                        />
                                    </Td>
                                    <Td>
                                        <NumberField
                                            noMargin
                                            {...form.getFieldProps("laterFeePercent")}
                                            onChange={(e) => {
                                                form.setValue("laterFeeType", "percentage");
                                                form.setValue(
                                                    "laterFeeAmount",
                                                    Number.parseFloat(
                                                        (
                                                            finalRefundAmount *
                                                            (Number.parseFloat(e.target.value || "0") / 100)
                                                        ).toFixed(2),
                                                    ),
                                                );
                                            }}
                                        />
                                    </Td>
                                    <Td>
                                        <SelectField
                                            isSearchable={false}
                                            noMargin
                                            {...form.getFieldProps("laterFeeType")}
                                            options={[
                                                { value: "percentage", label: "Percent" },
                                                { value: "amount", label: "Amount" },
                                            ]}
                                        />
                                    </Td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr className="mt-3 mb-3" />
                    <div className="flex flex-col gap-3 mb-3">
                        <div>
                            <div className="flex flex-row justify-between">
                                <div>Client hedge fund buyout:</div>
                                <SwitchNeoGen
                                    enabled={!!hedgeFundBuyoutEnabledController.field.value}
                                    setEnabled={() => {
                                        const isEnabled = !hedgeFundBuyoutEnabledController.field.value;
                                        hedgeFundBuyoutEnabledController.field.onChange(isEnabled);
                                        if (values.feeHedgeFundBuyoutEnabled) {
                                            form.setValue("feeHedgeFundBuyoutEnabled", false);
                                        }
                                    }}
                                />
                            </div>
                            <NumberField
                                label="Buyout price %"
                                disabled={!hedgeFundBuyoutEnabledController.field.value}
                                {...form.getFieldProps("hedgeFundBuyoutPrice")}
                                helperText={
                                    hedgeFundBuyoutEnabledController.field.value
                                        ? `${formatCurrency(
                                              values.totalFeeAmount || 0,
                                          )} is paid to ClearERC upfront from the hedge fund.`
                                        : ""
                                }
                            />
                        </div>
                        <div>
                            <div className="flex flex-row justify-between">
                                <div>ClearERC fee hedge fund buyout:</div>
                                <SwitchNeoGen
                                    enabled={!!feeHedgeFundBuyoutEnabledController.field.value}
                                    setEnabled={() => {
                                        const isEnabled = !feeHedgeFundBuyoutEnabledController.field.value;
                                        feeHedgeFundBuyoutEnabledController.field.onChange(isEnabled);
                                        if (values.hedgeFundBuyoutEnabled) {
                                            form.setValue("hedgeFundBuyoutEnabled", false);
                                        }
                                    }}
                                />
                            </div>
                            <NumberField
                                label="ClearERC fee hedge fund buyout %"
                                disabled={!feeHedgeFundBuyoutEnabledController.field.value}
                                {...form.getFieldProps("feeHedgeFundBuyoutPrice")}
                                helperText={
                                    feeHedgeFundBuyoutEnabledController.field.value
                                        ? `${formatCurrency(
                                              ((values.totalFeeAmount || 0) * (values.feeHedgeFundBuyoutPrice || 0)) /
                                                  100,
                                          )} is paid to ClearERC upfront from the hedge fund.`
                                        : ""
                                }
                            />
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateCompanyMutation.isLoading}>
                            Update Fee
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};

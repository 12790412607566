import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { useMutation } from "@tanstack/react-query";
import { Interaction } from "../domain/interaction";
import { useCreateInteraction } from "../hooks/use-create-interaction";
import { TextAreaField } from "../../layout/form/text-area-field";
import { useAuth } from "../../auth/use-auth";
import { SelectField } from "../../layout/form/select-field";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { Company } from "../../companies/domain/company";
import { UserCompany } from "../../user-companies/domain/user-company";

const schema = z.object({
    activity: z.string().min(1),
    calleeId: z.string().uuid().optional(),
});

type Data = z.infer<typeof schema>;

export const NewCallModal = ({
    onClose,
    onCallCreated: onCallCreated,
    forUserId,
    users,
    roles,
    company,
    userCompanies,
}: {
    onClose: () => any;
    onCallCreated: (interaction: Interaction) => any;
    forUserId: string;
    users: User[];
    roles: RoleGroup[];
    company: Company;
    userCompanies: UserCompany[];
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({ schema });
    const auth = useAuth();
    const user = auth.expectUser();

    const createInteractionMutation = useCreateInteraction();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const interaction = await createInteractionMutation.mutateAsync({
                authToken,
                data: {
                    activity: data.activity,
                    interaction_to: data.calleeId,
                    interaction_from: user.id,
                    type: 2, // Call
                    companyId: company.id,
                },
            });
            return interaction;
        },
    });

    const handleSubmit = async (data: Data) => {
        const interaction = await submitMutation.mutateAsync(data);
        onCallCreated(interaction);
    };

    return (
        <ModalDialog show title={"New Call"} close={onClose} showOk={false} showCancel={false} size="sm">
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={createInteractionMutation.error as any}>
                <SelectField
                    label="Callee"
                    {...form.getFieldProps("calleeId")}
                    options={users.map((user) => {
                        const currentRole = roles.filter((r) =>
                            userCompanies
                                .filter((uc) => uc.ercUserId === user.id && uc.companyId === company.id)
                                .map((uc) => uc.roleGroupId)
                                .includes(r.id || -1),
                        )[0] as RoleGroup | undefined;

                        return {
                            label: `${[user.firstName, user.lastName].join(" ")} (${user.phone}) (${
                                currentRole?.name
                            })`,
                            value: user.id,
                        };
                    })}
                />
                <TextAreaField label="Note" {...form.getFieldProps("activity")} placeholder="Add note..." />
                <div className="flex justify-end gap-4">
                    <ButtonNeoGen
                        type="outline"
                        disabled={createInteractionMutation.isLoading}
                        onClick={() => onClose()}
                    >
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={createInteractionMutation.isLoading}>
                        Create Call
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};

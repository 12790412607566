import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Interaction, interactionSchema } from "../domain/interaction";

export type UpdateInteractionData = Partial<Interaction>;

export const updateInteraction = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: number;
    data: UpdateInteractionData;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/interactions/${id}`,
        authToken,
        data,
    });

    const interaction = parseSchema(interactionSchema, response.data);

    return interaction;
};

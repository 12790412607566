import { useMutation } from "@tanstack/react-query";
import { UpdateInteractionData, updateInteraction } from "../actions/update-interaction";

export const useUpdateInteraction = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, id, data }: { authToken: string; id: number; data: UpdateInteractionData }) => {
            const interaction = await updateInteraction({ authToken, id, data });
            return interaction;
        },
    });
    return mutation;
};

import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { TextInputProps } from "./text-input";
import { Checkbox } from "./checkbox";

export const CheckboxField = ({
    name,
    form,
    isRequired,
    label,
    ...other
}: FormFieldProps<boolean> & GetFieldPropsReturn<any> & TextInputProps) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || false,
    });

    const error = fieldState.error?.message;
    return (
        <div className="flex flex-row items-center gap-2">
            <div style={{ marginTop: -14 }}>
                <Checkbox {...field} isChecked={field.value} />
            </div>
            <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}></Field>
        </div>
    );
};

import { useQuery } from "@tanstack/react-query";
import { getFiles } from "../actions/get-files";

type Params = {
    authToken: string;
    filters?: any;
};

const getFilesQueryKey = ({ authToken, filters }: Params) => {
    return ["files", { authToken, filters }];
};

export const useFiles = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getFilesQueryKey({ authToken, filters }),
        queryFn: async () => {
            const files = await getFiles({ authToken, filters });
            return files;
        },
    });
    return query;
};

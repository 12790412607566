import { useQuery } from "@tanstack/react-query";
import { getCompanies } from "../actions/get-companies";
import { CompanyFilters } from "../components/modals/company-filters-modal";

type Params = {
    authToken: string;
    filters?: any;
    adHocFilters?: CompanyFilters;
};

const getCompaniesQueryKey = ({ authToken, filters, adHocFilters }: Params) => {
    return ["companies", { authToken, filters, adHocFilters }];
};

export const useCompanies = ({ authToken, filters, adHocFilters }: Params) => {
    const query = useQuery({
        queryKey: getCompaniesQueryKey({ authToken, filters, adHocFilters }),
        queryFn: async () => {
            const companies = await getCompanies({ authToken, filters, adHocFilters });
            return companies;
        },
    });
    return query;
};
